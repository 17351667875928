import React, { useEffect, useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import MenuItem from "@material-ui/core/MenuItem";
// import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
// import { BASE_URL } from "../../../utils/index";
import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
// import moment, { add } from "moment";
// import DateFnsUtils from "@date-io/date-fns"; // choose your lib
// import Swal from "sweetalert2";
// import {
//   DatePicker,
//   TimePicker,
//   DateTimePicker,
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";
import "date-fns";
import Button from "@material-ui/core/Button";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 850,
  },
  formControl2: {
    margin: theme.spacing(1),
    minWidth: 860,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function SextoPaciente(props) {
  const classes = useStyles();
  console.log(props.values);

  const filter = createFilterOptions();
  const direccionDestinoPaciente6 = useRef(null); // Ref para el campo "Dirección Destino"
  const [inputValue, setInputValue] = useState(""); // Estado para manejar el valor del input
  useEffect(() => {
    if (window.google && inputValue.length >= 10) {
      // Verifica si hay al menos 3 caracteres
      const autocomplete = new window.google.maps.places.Autocomplete(
        direccionDestinoPaciente6.current,
        {
          types: ["geocode"],
          componentRestrictions: { country: ["es", "ad"] },
        }
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.formatted_address && place.geometry) {
          const address = place.formatted_address;
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();

          props.handleDataDireccion("direccionSextoPaciente")(address);

          // Formatea las coordenadas como una cadena "latitud,longitud"
          const coordenadas = `${lat},${lng}`;

          // Guarda las coordenadas como una cadena
          props.handleDataCoordenadas("coordenadasP6")(coordenadas);
        }
      });
    }
  }, [inputValue]); // Se ejecuta cuando cambia el valor del input

  const handleChange = (e) => {
    const value = e.target.value;
    setInputValue(value); // Actualiza el valor del input
    props.handleDataDireccion("direccionSextoPaciente")(value); // Actualiza el estado con el valor del input
  };
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Datos del sexto paciente (OPCIONAL)
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <Autocomplete
              // value={props.values.puebloPrimerPaciente}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  props.handlePacientes(newValue);
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  props.handlePacientes(newValue.inputValue);
                } else {
                  props.handlePacientes(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== "") {
                  filtered.push({
                    inputValue: params.inputValue,
                    title: `Usar: "${params.inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={props.values.pacientes}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.nombre;
              }}
              renderOption={(option) => option.nombre}
              // style={{ width: 300 }}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Busca un Paciente"
                  variant="outlined"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <Autocomplete
              value={props.values.puebloSextoPaciente}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  props.handlePueblo(newValue);
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  props.handlePueblo(newValue.inputValue);
                } else {
                  props.handlePueblo(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== "") {
                  filtered.push({
                    inputValue: params.inputValue,
                    title: `Usar: "${params.inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={props.values.pueblos}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.title;
              }}
              renderOption={(option) => option.title}
              // style={{ width: 300 }}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Escribe un pueblo"
                  variant="outlined"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              value={props.values.sextoPaciente}
              required
              id="city"
              name="city"
              label="Nombre y apellido"
              fullWidth
              autoComplete="shipping address-level2"
              onChange={props.handleData("sextoPaciente")}
              defaultValue={props.values.sextoPaciente}
              inputProps={{
                maxLength: 40,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              value={props.values.direccionSextoPaciente}
              required
              id="direccionSextoPaciente"
              name="direccionSextoPaciente"
              label="Dirección Sexto Paciente"
              fullWidth
              inputRef={direccionDestinoPaciente6} // Referencia al input para Google Places
              onChange={handleChange} // Maneja cambios en el input
              defaultValue={props.values.direccionSextoPaciente}
              inputProps={{
                maxLength: 40,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              value={props.values.telfSextoPaciente}
              required
              id="city"
              name="city"
              label="Teléfono"
              fullWidth
              autoComplete="shipping address-level2"
              onChange={props.handleData("telfSextoPaciente")}
              defaultValue={props.values.telfSextoPaciente}
              inputProps={{
                maxLength: 50,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              value={props.values.telfSextoPaciente2}
              id="city"
              name="city"
              label="Teléfono alternativo"
              fullWidth
              autoComplete="shipping address-level2"
              onChange={props.handleData("telfSextoPaciente2")}
              defaultValue={props.values.telfSextoPaciente2}
              inputProps={{
                maxLength: 50,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              value={props.values.NASS6}
              required
              id="city"
              name="city"
              label="NASS-CIP"
              fullWidth
              autoComplete="shipping address-level2"
              onChange={props.handleData("NASS6")}
              defaultValue={props.values.NASS6}
              inputProps={{
                maxLength: 18,
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <div className={classes.buttons}>
        {props.activeStep !== 0 && (
          <Button onClick={props.prevStep} className={classes.button}>
            Regresar
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={props.nextStep}
          className={classes.button}
        >
          {props.activeStep === 8 && props.activeStep < 9
            ? "Solicitar viaje"
            : "Siguiente"}
        </Button>
      </div>
    </React.Fragment>
  );
}
