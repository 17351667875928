import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
// import { set } from "date-fns";
import Exitoso from "../Exitoso";
import Error from "../Error";

import { BASE_URL } from "../../../utils/index";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function LoadingServicio(props) {
  useEffect(() => {
    const requestHandle = async () => {
      //   //   //Data
      const {
        tipo,
        direccionHospital,
        fechaCompletaEnviar,
        primerPaciente,
        puebloPrimerPaciente,
        direccionPrimerPaciente,
        telfPrimerPaciente,
        telfPrimerPaciente2,
        NASS1,
        segundoPaciente,
        puebloSegundoPaciente,
        direccionSegundoPaciente,
        telfSegundoPaciente,
        telfSegundoPaciente2,
        NASS2,

        tercerPaciente,
        puebloTercerPaciente,
        telfTercerPaciente,
        telfTercerPaciente2,
        direccionTercerPaciente,
        NASS3,

        cuartoPaciente,
        puebloCuartoPaciente,
        telfCuartoPaciente,
        telfCuartoPaciente2,
        direccionCuartoPaciente,
        NASS4,

        quintoPaciente,
        puebloQuintoPaciente,
        telfQuintoPaciente,
        telfQuintoPaciente2,
        direccionQuintoPaciente,
        NASS5,

        sextoPaciente,
        puebloSextoPaciente,
        telfSextoPaciente,
        telfSextoPaciente2,
        direccionSextoPaciente,
        NASS6,

        destino,
        sesiones,
        email,
        password,
        idCliente,
        idClienteSol,
        perfilLog,
        idPaciente,
        idPaciente2,
        idPaciente3,
        idPaciente4,
        idPaciente5,
        idPaciente6,
        urgente,
        fechaCompletaEnviarTarde,
        mananaTarde,
        identificadorIda,
        identificadorVuelta,
        direccionDestino,
        coordenadasP1,
        coordenadasP2,
        coordenadasP3,
        coordenadasP4,
        coordenadasP5,
        coordenadasP6,
        coordenadasDestino,
        vacioAlta,
        vacioVuelta,
        destino1,
        destino1Destino,
        destino1direccionDestino,
        destino1coordenadasDestino,
        destino2,
        destino2Destino,
        destino2direccionDestino,
        destino2coordenadasDestino,
        destino3,
        destino3Destino,
        destino3direccionDestino,
        destino3coordenadasDestino,
        origenPuebloModificado,
        origenDireccionModificada,
        origenCoordenadaModificada,
        origenModificado,
        rehabilitacion,
      } = props.values;
      //   //Fetch
      // console.log(props.values);
      let csrfToken = localStorage.getItem("csrfToken");
      console.log(csrfToken);
      let response = await fetch(`${BASE_URL}/pedirViajeMutuas`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": csrfToken, // Adjuntar el token CSRF como encabezado
        },
        body: JSON.stringify({
          email: email,
          password: password,
          fecha: fechaCompletaEnviar,
          fechaTarde: fechaCompletaEnviarTarde,
          tipo: tipo,
          primerPaciente: primerPaciente,
          puebloPrimerPaciente: puebloPrimerPaciente.title,
          telfPrimerPaciente: telfPrimerPaciente,
          telfPrimerPaciente2:
            telfPrimerPaciente2 !== "" ? telfPrimerPaciente2 : "NO",
          direccionPrimerPaciente: direccionPrimerPaciente,
          NASS1: NASS1,
          //segundo
          segundoPaciente: segundoPaciente,
          puebloSegundoPaciente: puebloSegundoPaciente.title,
          telfSegundoPaciente: telfSegundoPaciente,
          telfSegundoPaciente2:
            telfSegundoPaciente2 !== "" ? telfSegundoPaciente2 : "NO",
          direccionSegundoPaciente: direccionSegundoPaciente,
          NASS2: NASS2,
          //tercero
          tercerPaciente: tercerPaciente,
          puebloTercerPaciente: puebloTercerPaciente.title,
          telfTercerPaciente: telfTercerPaciente,
          telfTercerPaciente2:
            telfTercerPaciente2 !== "" ? telfTercerPaciente2 : "NO",
          direccionTercerPaciente: direccionTercerPaciente,
          NASS3: NASS3,
          //cuarto
          cuartoPaciente: cuartoPaciente,
          puebloCuartoPaciente: puebloCuartoPaciente.title,
          telfCuartoPaciente: telfCuartoPaciente,
          telfCuartoPaciente2:
            telfCuartoPaciente2 !== "" ? telfCuartoPaciente2 : "NO",
          direccionCuartoPaciente: direccionCuartoPaciente,
          NASS4: NASS4,
          //quinto
          quintoPaciente: quintoPaciente,
          puebloQuintoPaciente: puebloQuintoPaciente.title,
          telfQuintoPaciente: telfQuintoPaciente,
          telfQuintoPaciente2:
            telfQuintoPaciente2 !== "" ? telfQuintoPaciente2 : "NO",
          direccionQuintoPaciente: direccionQuintoPaciente,
          NASS5: NASS5,
          //sexto
          sextoPaciente: sextoPaciente,
          puebloSextoPaciente: puebloSextoPaciente.title,
          telfSextoPaciente: telfSextoPaciente,
          telfSextoPaciente2:
            telfSextoPaciente2 !== "" ? telfSextoPaciente2 : "NO",
          direccionSextoPaciente: direccionSextoPaciente,
          NASS6: NASS6,

          direccionHospital: direccionHospital.title,
          idCliente: Number(perfilLog) === 5 ? idClienteSol.id : idCliente,
          destino: destino.title,
          sesiones: sesiones,
          idPaciente: idPaciente,
          idPaciente2: idPaciente2,
          idPaciente3: idPaciente3,
          idPaciente4: idPaciente4,
          idPaciente5: idPaciente5,
          idPaciente6: idPaciente6,
          urgente: urgente,
          mananaTarde: mananaTarde,
          identificadorIda: identificadorIda,
          identificadorVuelta: identificadorVuelta,
          direccionDestino: direccionDestino,
          coordenadasP1: coordenadasP1,
          coordenadasP2: coordenadasP2,
          coordenadasP3: coordenadasP3,
          coordenadasP4: coordenadasP4,
          coordenadasP5: coordenadasP5,
          coordenadasP6: coordenadasP6,
          coordenadasDestino: coordenadasDestino,
          vacioAlta: vacioAlta,
          vacioVuelta: vacioVuelta,
          destino1: destino1,
          destino1Destino: destino1Destino.title,
          destino1direccionDestino: destino1direccionDestino,
          destino1coordenadasDestino: destino1coordenadasDestino,
          destino2: destino2,
          destino2Destino: destino2Destino.title,
          destino2direccionDestino: destino2direccionDestino,
          destino2coordenadasDestino: destino2coordenadasDestino,
          destino3: destino3,
          destino3Destino: destino3Destino.title,
          destino3direccionDestino: destino3direccionDestino,
          destino3coordenadasDestino: destino3coordenadasDestino,
          origenPuebloModificado: origenPuebloModificado.title,
          origenDireccionModificada: origenDireccionModificada,
          origenCoordenadaModificada: origenCoordenadaModificada,
          origenModificado: origenModificado,
          rehabilitacion: rehabilitacion,
        }),
      });
      // console.log(response);
      let response2 = await response.json();
      console.log(response2);
      // const { message, id } = response2;
      const { message } = response2;
      if (message !== undefined || message != null) {
        setStateComponent({
          ...stateComponent,
          isLoading: false,
          isSuccess: true,
          message: message,
        });
      } else {
        setStateComponent({
          ...stateComponent,
          isError: true,
        });
      }
    };
    requestHandle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  const [stateComponent, setStateComponent] = useState({
    isLoading: true,
    isSuccess: false,
    isError: false,
    message: "",
  });

  if (stateComponent.isLoading)
    return (
      <div className={classes.root}>
        <LinearProgress />
      </div>
    );

  if (stateComponent.isSuccess) {
    return <Exitoso message={stateComponent.message} />;
  }

  if (stateComponent.isError) {
    return <Error />;
  }
}
