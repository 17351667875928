import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { BASE_URL } from "../../../utils/index";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import moment, { add } from "moment";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import Swal from "sweetalert2";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "date-fns";
import Button from "@material-ui/core/Button";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 500,
  },
  formControl2: {
    margin: theme.spacing(1),
    minWidth: 510,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function PrimerPaciente(props) {
  const classes = useStyles();
  const filter = createFilterOptions();
  console.log(props.values);
  const handleChange = (e) => {};

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Datos del primer paciente
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <Autocomplete
              // value={props.values.puebloPrimerPaciente}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  props.handlePacientes(newValue);
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  props.handlePacientes(newValue.inputValue);
                } else {
                  props.handlePacientes(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== "") {
                  filtered.push({
                    inputValue: params.inputValue,
                    title: `Usar: "${params.inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={props.values.pacientes}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.nombre;
              }}
              renderOption={(option) => option.nombre}
              // style={{ width: 300 }}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Busca un Paciente"
                  variant="outlined"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <Autocomplete
              value={props.values.puebloPrimerPaciente}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  props.handlePueblo1(newValue);
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  props.handlePueblo1(newValue.inputValue);
                } else {
                  props.handlePueblo1(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== "") {
                  filtered.push({
                    inputValue: params.inputValue,
                    title: `Usar: ${params.inputValue}`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={props.values.pueblos}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.title;
              }}
              renderOption={(option) => option.title}
              // style={{ width: 300 }}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Escribe un pueblo"
                  variant="outlined"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              value={props.values.primerPaciente}
              required
              id="city"
              name="city"
              label="Nombre y apellido"
              fullWidth
              rowsMax={15}
              autoComplete="shipping address-level2"
              onChange={props.handleData("primerPaciente")}
              defaultValue={props.values.primerPaciente}
              inputProps={{
                maxLength: 40,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              value={props.values.direccionPrimerPaciente}
              required
              id="city"
              name="city"
              label="Dirección"
              fullWidth
              autoComplete="shipping address-level2"
              onChange={props.handleData("direccionPrimerPaciente")}
              defaultValue={props.values.direccionPrimerPaciente}
              inputProps={{
                maxLength: 40,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              value={props.values.telfPrimerPaciente}
              required
              id="city"
              name="city"
              label="Teléfono"
              fullWidth
              autoComplete="shipping address-level2"
              onChange={props.handleData("telfPrimerPaciente")}
              defaultValue={props.values.telfPrimerPaciente}
              inputProps={{
                maxLength: 50,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              value={props.values.telfPrimerPaciente2}
              id="city"
              name="city"
              label="Teléfono alternativo"
              fullWidth
              autoComplete="shipping address-level2"
              onChange={props.handleData("telfPrimerPaciente2")}
              defaultValue={props.values.telfPrimerPaciente2}
              inputProps={{
                maxLength: 50,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              value={props.values.NASS1}
              required
              id="city"
              name="city"
              label="NASS-CIP"
              fullWidth
              autoComplete="shipping address-level2"
              onChange={props.handleData("NASS1")}
              defaultValue={props.values.NASS1}
              inputProps={{
                maxLength: "18",
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <div className={classes.buttons}>
        {props.activeStep !== 0 && (
          <Button onClick={props.prevStep} className={classes.button}>
            Regresar
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={props.nextStep}
          className={classes.button}
        >
          {props.activeStep === 4 && props.activeStep < 5
            ? "Solicitar viaje"
            : "Siguiente"}
        </Button>
      </div>
    </React.Fragment>
  );
}
