import React, { useEffect, useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// import moment, { add } from "moment";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import {
  // DatePicker,
  // TimePicker,
  // DateTimePicker,
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import "date-fns";
import { ca } from "date-fns/locale";

//Estilos
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 850,
  },
  formControl2: {
    margin: theme.spacing(1),
    minWidth: 860,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function ViajeDetalles(props) {
  const classes = useStyles();
  const filter = createFilterOptions();
  const direccionDestinoRef = useRef(null); // Ref para el campo "Dirección Destino"
  const direccionDestino1Ref = useRef(null); // Ref para el campo "Dirección Destino"
  const direccionDestino2Ref = useRef(null); // Ref para el campo "Dirección Destino"
  const direccionDestino3Ref = useRef(null); // Ref para el campo "Dirección Destino"
  const direccionOrigenRef = useRef(null); // Ref para el campo "Dirección Destino"
  const [inputValue, setInputValue] = useState(""); // Estado para manejar el valor del input

  const initializeAutocomplete = (
    ref,
    direccionDestino,
    coordenadasDestino
  ) => {
    if (window.google && ref.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        ref.current,
        {
          types: ["geocode"],
          componentRestrictions: { country: ["es", "ad"] },
        }
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.formatted_address && place.geometry) {
          const address = place.formatted_address;
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();

          // Guarda la dirección y las coordenadas en el destino correspondiente
          props.handleDataDireccion(direccionDestino)(address);
          const coordenadas = `${lat},${lng}`;
          props.handleDataCoordenadas(coordenadasDestino)(coordenadas);
        }
      });
    }
  };
  //DESTINO
  useEffect(() => {
    if (
      direccionDestinoRef.current &&
      direccionDestinoRef.current.value.length >= 10
    ) {
      initializeAutocomplete(
        direccionDestinoRef,
        "direccionDestino",
        "coordenadasDestino"
      );
    }
  }, [direccionDestinoRef, direccionDestinoRef.current?.value]);
  //DESTINO1
  useEffect(() => {
    if (
      direccionDestino1Ref.current &&
      direccionDestino1Ref.current.value.length >= 10 &&
      props.values.destino1
    ) {
      initializeAutocomplete(
        direccionDestino1Ref,
        "destino1direccionDestino",
        "destino1coordenadasDestino"
      );
    }
  }, [
    direccionDestino1Ref,
    direccionDestino1Ref.current?.value,
    props.values.destino1,
  ]);
  //DESTINO2
  useEffect(() => {
    if (
      direccionDestino2Ref.current &&
      direccionDestino2Ref.current.value.length >= 10 &&
      props.values.destino2
    ) {
      initializeAutocomplete(
        direccionDestino2Ref,
        "destino2direccionDestino",
        "destino2coordenadasDestino"
      );
    }
  }, [
    direccionDestino2Ref,
    direccionDestino2Ref.current?.value,
    props.values.destino2,
  ]);
  //DESTINO3
  useEffect(() => {
    if (
      direccionDestino3Ref.current &&
      direccionDestino3Ref.current.value.length >= 10 &&
      props.values.destino3
    ) {
      initializeAutocomplete(
        direccionDestino3Ref,
        "destino3direccionDestino",
        "destino3coordenadasDestino"
      );
    }
  }, [
    direccionDestino3Ref,
    direccionDestino3Ref.current?.value,
    props.values.destino3,
  ]);
  //ORIGEN MODIFICADO
  useEffect(() => {
    if (
      direccionOrigenRef.current &&
      direccionOrigenRef.current.value.length >= 10 &&
      props.values.origenModificado
    ) {
      initializeAutocomplete(
        direccionOrigenRef,
        "origenDireccionModificada",
        "origenCoordenadaModificada"
      );
    }
  }, [
    direccionOrigenRef,
    direccionOrigenRef.current?.value,
    props.values.origenModificado,
  ]);

  const handleChange = (e) => {
    const value = e.target.value;
    setInputValue(value); // Actualiza el valor del input
    props.handleDataDireccion("direccionDestino")(value); // Actualiza el estado con el valor del input
  };
  const handleChange1 = (e) => {
    const value = e.target.value;
    setInputValue(value); // Actualiza el valor del input
    props.handleDataDireccion("destino1direccionDestino")(value); // Actualiza el estado con el valor del input
  };
  const handleChange2 = (e) => {
    const value = e.target.value;
    setInputValue(value); // Actualiza el valor del input
    props.handleDataDireccion("destino2direccionDestino")(value); // Actualiza el estado con el valor del input
  };
  const handleChange3 = (e) => {
    const value = e.target.value;
    setInputValue(value); // Actualiza el valor del input
    props.handleDataDireccion("destino3direccionDestino")(value); // Actualiza el estado con el valor del input
  };
  const handleChangeOrigen = (e) => {
    const value = e.target.value;
    setInputValue(value); // Actualiza el valor del input
    props.handleDataDireccion("origenDireccionModificada")(value); // Actualiza el estado con el valor del input
  };
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Información del viaje
      </Typography>

      <Grid container spacing={3}>
        {Number(props.values.perfilLog) === 5 ? (
          <Grid item xs={12}>
            <FormControl className={classes.formControl2}>
              <Autocomplete
                value={props.values.idClienteSol}
                onChange={(event, newValue) => {
                  if (typeof newValue == "string") {
                    props.handleClientes(newValue);
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    props.handleClientes(newValue.inputValue);
                  } else {
                    props.handleClientes(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  // Suggest the creation of a new value
                  if (params.inputValue !== "") {
                    filtered.push({
                      inputValue: params.inputValue,
                      nombre: `Usar: "${params.inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={props.values.clientes}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option == "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.nombre;
                }}
                renderOption={(option) => option.nombre}
                // style={{ width: 300 }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Escribe el Cliente que solicita"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
            <Grid item xs={12} container justify="space-between">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.values.urgente}
                    onChange={(event) =>
                      props.handleUrgente(event.target.checked)
                    }
                  />
                }
                label="Urgente Assignacion Frontal"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.values.vacioAlta}
                    onChange={(event) => props.handleAlta(event.target.checked)}
                  />
                }
                label="Vacio por Alta"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.values.vacioVuelta}
                    onChange={(event) =>
                      props.handleIngreso(event.target.checked)
                    }
                  />
                }
                label="Vacio por Ingreso"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.values.origenModificado}
                    onChange={(event) =>
                      props.handleOrigenM(event.target.checked)
                    }
                  />
                }
                label="Modificar Origen"
              />
              {props.values.origenModificado ? (
                <Grid>
                  <Typography variant="h6" gutterBottom>
                    Indicar Origin de la Mutua
                  </Typography>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl2}>
                      <Autocomplete
                        value={props.values.origenPuebloModificado}
                        onChange={(event, newValue) => {
                          if (typeof newValue == "string") {
                            props.handleOrigenModificado(newValue);
                          } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            props.handleOrigenModificado(newValue.inputValue);
                          } else {
                            props.handleOrigenModificado(newValue);
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          // Suggest the creation of a new value
                          if (params.inputValue !== "") {
                            filtered.push({
                              inputValue: params.inputValue,
                              title: `Usar: "${params.inputValue}"`,
                            });
                          }

                          return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="free-solo-with-text-demo"
                        options={props.values.todosDestinos}
                        getOptionLabel={(option) => {
                          // Value selected with enter, right from the input
                          if (typeof option == "string") {
                            return option;
                          }
                          // Add "xxx" option created dynamically
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          // Regular option
                          return option.title;
                        }}
                        renderOption={(option) => option.title}
                        // style={{ width: 300 }}
                        freeSolo
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Escribe un pueblo de Origen Modificado"
                            variant="outlined"
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl2}>
                      <TextField
                        value={props.values.origenDireccionModificada}
                        required
                        id="origenDireccionModificada"
                        name="origenDireccionModificada"
                        label="Dirección Origen Modificado"
                        fullWidth
                        variant="outlined"
                        inputRef={direccionOrigenRef} // Referencia al input para Google Places
                        onChange={handleChangeOrigen} // Maneja cambios en el input
                        inputProps={{
                          maxLength: 80,
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              ) : null}
              <FormControl className={classes.formControl2}>
                <TextField
                  value={props.values.identificadorIda}
                  required
                  id="city"
                  name="city"
                  label="Identificador Ida"
                  fullWidth
                  autoComplete="shipping address-level2"
                  onChange={props.handleData("identificadorIda")}
                  defaultValue={props.values.identificadorIda}
                  inputProps={{
                    maxLength: 40,
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControl2}>
                <TextField
                  value={props.values.identificadorVuelta}
                  id="city"
                  name="city"
                  label="Identificador Vuelta"
                  fullWidth
                  autoComplete="shipping address-level2"
                  onChange={props.handleData("identificadorVuelta")}
                  defaultValue={props.values.identificadorVuelta}
                  inputProps={{
                    maxLength: 40,
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <FormControl className={classes.formControl2}>
            <InputLabel id="demo-simple-select-label">
              Selecciona el tipo de vehículo
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.values.tipo}
              name="tipo"
              onChange={(e) => props.handleVehiculo(e.target.value)}
            >
              <MenuItem value={"TURISME"}>TURISME</MenuItem>
              <MenuItem value={"ADAPTAT"}>ADAPTAT</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Typography variant="h6" gutterBottom>
          Destino Final
        </Typography>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.values.destino1}
                onChange={(event) => props.handleDestino1(event.target.checked)}
              />
            }
            label="D 1"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={props.values.destino2}
                onChange={(event) => props.handleDestino2(event.target.checked)}
              />
            }
            label="D 2"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={props.values.destino3}
                onChange={(event) => props.handleDestino3(event.target.checked)}
              />
            }
            label="D 3"
          />
          <FormControl className={classes.formControl2}>
            <Autocomplete
              value={props.values.destino}
              onChange={(event, newValue) => {
                if (typeof newValue == "string") {
                  props.handleDestino(newValue);
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  props.handleDestino(newValue.inputValue);
                } else {
                  props.handleDestino(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== "") {
                  filtered.push({
                    inputValue: params.inputValue,
                    title: `Usar: "${params.inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={props.values.todosDestinos}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option == "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.title;
              }}
              renderOption={(option) => option.title}
              // style={{ width: 300 }}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Escribe un pueblo destino"
                  variant="outlined"
                />
              )}
            />
          </FormControl>
          <FormControl className={classes.formControl2}>
            <TextField
              value={props.values.direccionDestino}
              required
              id="direccionDestino"
              name="direccionDestino"
              label="Dirección Destino"
              fullWidth
              variant="outlined"
              inputRef={direccionDestinoRef} // Referencia al input para Google Places
              onChange={handleChange} // Maneja cambios en el input
              inputProps={{
                maxLength: 80,
              }}
            />
          </FormControl>
        </Grid>
        {props.values.destino1 ? (
          <>
            <Typography variant="h6" gutterBottom>
              Destino 1 adicional
            </Typography>
            <Grid item xs={12}>
              <FormControl className={classes.formControl2}>
                <Autocomplete
                  value={props.values.destino1Destino}
                  onChange={(event, newValue) => {
                    if (typeof newValue == "string") {
                      props.handleDestinoViaje1(newValue);
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      props.handleDestinoViaje1(newValue.inputValue);
                    } else {
                      props.handleDestinoViaje1(newValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    // Suggest the creation of a new value
                    if (params.inputValue !== "") {
                      filtered.push({
                        inputValue: params.inputValue,
                        title: `Usar: "${params.inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="free-solo-with-text-demo"
                  options={props.values.todosDestinos}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option == "string") {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.title;
                  }}
                  renderOption={(option) => option.title}
                  // style={{ width: 300 }}
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Escribe un pueblo destino"
                      variant="outlined"
                    />
                  )}
                />
              </FormControl>
              <FormControl className={classes.formControl2}>
                <TextField
                  value={props.values.destino1direccionDestino}
                  required
                  id="destino1direccionDestino"
                  name="destino1direccionDestino"
                  label="Dirección Destino"
                  fullWidth
                  variant="outlined"
                  inputRef={direccionDestino1Ref} // Referencia al input para Google Places
                  onChange={handleChange1} // Maneja cambios en el input
                  inputProps={{
                    maxLength: 80,
                  }}
                />
              </FormControl>
            </Grid>
          </>
        ) : null}
        {props.values.destino2 ? (
          <>
            <Typography variant="h6" gutterBottom>
              Destino 2 adicional
            </Typography>
            <Grid item xs={12}>
              <FormControl className={classes.formControl2}>
                <Autocomplete
                  value={props.values.destino2Destino}
                  onChange={(event, newValue) => {
                    if (typeof newValue == "string") {
                      props.handleDestinoViaje2(newValue);
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      props.handleDestinoViaje2(newValue.inputValue);
                    } else {
                      props.handleDestinoViaje2(newValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    // Suggest the creation of a new value
                    if (params.inputValue !== "") {
                      filtered.push({
                        inputValue: params.inputValue,
                        title: `Usar: "${params.inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="free-solo-with-text-demo"
                  options={props.values.todosDestinos}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option == "string") {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.title;
                  }}
                  renderOption={(option) => option.title}
                  // style={{ width: 300 }}
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Escribe un pueblo destino"
                      variant="outlined"
                    />
                  )}
                />
              </FormControl>
              <FormControl className={classes.formControl2}>
                <TextField
                  value={props.values.destino2direccionDestino}
                  required
                  id="destino2direccionDestino"
                  name="destino2direccionDestino"
                  label="Dirección Destino"
                  fullWidth
                  variant="outlined"
                  inputRef={direccionDestino2Ref} // Referencia al input para Google Places
                  onChange={handleChange2} // Maneja cambios en el input
                  inputProps={{
                    maxLength: 80,
                  }}
                />
              </FormControl>
            </Grid>
          </>
        ) : null}
        {props.values.destino3 ? (
          <>
            <Typography variant="h6" gutterBottom>
              Destino 3 adicional
            </Typography>
            <Grid item xs={12}>
              <FormControl className={classes.formControl2}>
                <Autocomplete
                  value={props.values.destino3Destino}
                  onChange={(event, newValue) => {
                    if (typeof newValue == "string") {
                      props.handleDestinoViaje3(newValue);
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      props.handleDestinoViaje3(newValue.inputValue);
                    } else {
                      props.handleDestinoViaje3(newValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    // Suggest the creation of a new value
                    if (params.inputValue !== "") {
                      filtered.push({
                        inputValue: params.inputValue,
                        title: `Usar: "${params.inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="free-solo-with-text-demo"
                  options={props.values.todosDestinos}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option == "string") {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.title;
                  }}
                  renderOption={(option) => option.title}
                  // style={{ width: 300 }}
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Escribe un pueblo destino"
                      variant="outlined"
                    />
                  )}
                />
              </FormControl>
              <FormControl className={classes.formControl2}>
                <TextField
                  value={props.values.destino3direccionDestino}
                  required
                  id="destino3direccionDestino"
                  name="destino3direccionDestino"
                  label="Dirección Destino"
                  fullWidth
                  variant="outlined"
                  inputRef={direccionDestino3Ref} // Referencia al input para Google Places
                  onChange={handleChange3} // Maneja cambios en el input
                  inputProps={{
                    maxLength: 80,
                  }}
                />
              </FormControl>
            </Grid>
          </>
        ) : null}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.values.rehabilitacion}
                onChange={(event) =>
                  props.handleRehabilitacion(event.target.checked)
                }
              />
            }
            label="Re-habilitacion"
          />
          <FormControl className={classes.formControl2}>
            <TextField
              required
              id="city"
              name="city"
              type="number"
              label="Cuando dias realizaremos el servició"
              fullWidth
              value={props.values.sesiones}
              onChange={props.handleData("sesiones")}
              defaultValue={props.values.sesiones}
            />
          </FormControl>
          {/*   <FormControlLabel
            control={
              <Checkbox
                checked={props.values.mananaTarde}
                onChange={(event) =>
                  props.handleMananaTarde(event.target.checked)
                }
              />
            }
            label="Marcar para crear Viajes Mañana y tarde"
          /> */}
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl2}>
            <Autocomplete
              value={props.values.direccionHospital}
              onChange={(event, newValue) => {
                if (typeof newValue == "string") {
                  props.handleCentros(newValue);
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  props.handleCentros(newValue.inputValue);
                } else {
                  props.handleCentros(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== "") {
                  filtered.push({
                    inputValue: params.inputValue,
                    title: `Usar: "${params.inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={props.values.todosCentros}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option == "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.title;
              }}
              renderOption={(option) => option.title}
              // style={{ width: 300 }}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Escribe el Centro de Asistencia"
                  variant="outlined"
                />
              )}
            />
          </FormControl>
        </Grid>
        {props.values.mananaTarde ? (
          <>
            {/* Parte de la mañana */}
            <Grid item xs={12} container justify="space-between">
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ca}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog-morning"
                  label="Selecciona una fecha (Mañana)"
                  format="dd/MM/yyyy"
                  value={props.values.fecha}
                  onChange={(date) => props.handleConfirm(date, "FechaManana")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker-morning"
                  label="Selecciona una hora (Mañana)"
                  ampm={false}
                  value={props.values.hora}
                  onChange={(date) => props.handleConfirm(date, "HoraManana")}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="city-morning"
                  name="city-morning"
                  disabled
                  label="Seleccionaste (Mañana):"
                  fullWidth
                  value={props.values.fechaMostrar}
                />
              </FormControl>
            </Grid>
            {/* Campos para la tarde */}
            <Grid item xs={12} container justify="space-between">
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ca}>
                <KeyboardDatePicker
                  disabled
                  margin="normal"
                  id="date-picker-dialog-afternoon"
                  label="Selecciona una fecha (Tarde)"
                  format="dd/MM/yyyy"
                  value={props.values.fecha}
                  onChange={(date) =>
                    props.handleConfirmTarde(date, "FechaTarde")
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker-afternoon"
                  label="Selecciona una hora (Tarde)"
                  ampm={false}
                  value={props.values.horaTarde}
                  onChange={(date) =>
                    props.handleConfirmTarde(date, "HoraTarde")
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="city-afternoon"
                  name="city-afternoon"
                  disabled
                  label="Seleccionaste (Tarde):"
                  fullWidth
                  value={props.values.fechaMostrarTarde}
                />
              </FormControl>
            </Grid>
          </>
        ) : (
          //Cuando es solo mañana
          <>
            <Grid item xs={12} container justify="space-between">
              <MuiPickersUtilsProvider utils={DateFnsUtils} local={ca}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Selecciona una fecha"
                  format="dd/MM/yyyy"
                  value={props.values.fecha}
                  onChange={(date) => props.handleConfirm(date, "Fecha")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  label="Selecciona una hora"
                  ampm={false}
                  value={props.values.hora}
                  onChange={(date) => props.handleConfirm(date, "Hora")}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="city"
                  name="city"
                  disabled
                  label="Seleccionaste:"
                  fullWidth
                  autoComplete="shipping address-level2"
                  value={props.values.fechaMostrar}
                />
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>
      <div className={classes.buttons}>
        {Number(props.activeStep) !== 0 && (
          <Button onClick={props.prevStep} className={classes.button}>
            Regresar
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={props.nextStep}
          className={classes.button}
        >
          {Number(props.activeStep) === 4 && props.activeStep < 5
            ? "Solicitar viaje"
            : "Siguiente"}
        </Button>
      </div>
    </React.Fragment>
  );
}
